<template>

    <Sidebar v-model:visible="ShowCharts" position="right" class="p-sidebar-md">
        <template v-for="[key, value] of Object.entries(Charts)" v-bind:key="key">
            <h3>{{value.Name}}</h3>
            <Chart type="pie" :data="value" :options="chartOptions" v-if="Loading===false"/>
            <ProgressSpinner v-else/>
        </template>
    </Sidebar>

    <h3 v-if="Loading==false">Total records &mdash; {{ TotalRecords }}</h3>

    <DataTable
        :rowHover="true"
        responsiveLayout="scroll"
        :loading="Loading"
        :value="data"
        :rows="LazyFilters.Limit"
        :first="LazyFilters.Offset"
        :sortField="LazyFilters.SortField"
        :sortOrder="LazyFilters.SortOrder"
        :paginator="true"
        :lazy="true"
        :totalRecords="TotalRecords"
        showGridlines
        @page="onPage($event)"
        @sort="onPage($event)"
        v-model:filters="LazyFilters.filters"
        filterDisplay="row"
        v-model:expandedRows="expandedRows"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
    >
        <template #header>

            <div class="p-d-flex p-jc-between">
                <Button icon="pi pi-external-link" label="Export" @click="exportCSV($event)"/>
                <span class="p-input-icon-left">
                    <Button icon="pi pi-chart-bar" @click="showCharts" />
                </span>
            </div>

        </template>

        <template #loading>
            Loading...
        </template>
        <template #empty>
            No records found.
        </template>

        <Column :expander="true" headerStyle="width: 4rem" />

        <Column field="Email" header="Email" :showFilterMenu="false" :sortable="true">
            <template #filter>
                <InputText type="text" v-model="LazyFilters.filters.Email" @keydown.enter="loadUsers()" class="p-column-filter"/>
            </template>

            <template #body="s">
                <span v-tooltip.bottom="s.data.Email" class="truncator" >{{ s.data.Email }}</span>
            </template>
        </Column>
        <Column field="Username" header="User Name" :showFilterMenu="false" :sortable="true">

            <template #filter>
                <InputText type="text" v-model="LazyFilters.filters.Username" @keydown.enter="loadUsers()" class="p-column-filter"/>
            </template>

            <template #body="s">
                <span v-tooltip.bottom="s.data.Username" class="truncator">{{ s.data.Username }}</span>
            </template>
        </Column>

        <Column field="Role" header="Role" :showFilterMenu="false" :sortable="true">

            <template #filter>
                <Dropdown v-model="LazyFilters.filters.Role" @change="loadUsers()" :options="roles" :filter="true" :showClear="true"></Dropdown>
            </template>

            <template #body="s">
                <span style="max-width:300px" class="truncator">{{ s.data.Role?.join(", ") }}</span>
            </template>
        </Column>

        <Column field="Added" header="Added" :showFilterMenu="false" :sortable="true">
            <template #body="s">
                <span class="truncator">{{printFormat('time', s.data.Added) }}</span>
            </template>
        </Column>

        <Column field="LastLogin" header="Last Login" :sortable="true">
            <template #body="s">
                <span class="truncator">{{printFormat('time', s.data.LastLogin)}}</span>
            </template>
        </Column>

        <Column field="IsActive" header="Is Active" :sortable="true" :showFilterMenu="false" style="width: 130px">

            <template #filter>
                <TriStateCheckbox v-model="isActive" @change="loadUsers()" />
            </template>


            <template #body="s">
                <span v-if="s.data.IsActive === true"><i class="pi pi-check"></i></span>
                <span v-else><i class="pi pi-times"></i></span>
            </template>
        </Column>

        <Column field="IsStaff" header="Is Staff" :sortable="true" :showFilterMenu="false" style="width: 130px">

            <template #filter>
                <TriStateCheckbox v-model="isStaff" @change="loadUsers()" />
            </template>

            <template #body="s">
                <span v-if="s.data.IsStaff === true"><i class="pi pi-check"></i></span>
                <span v-else><i class="pi pi-times"></i></span>
            </template>
        </Column>

        <template #expansion="slotProps">
            <div class="p-grid">

                <div class="p-col-6">
                    <h3>User Details</h3>
                    <table class="details">
                        <tr v-for="(v,k) in slotProps.data" v-bind:key="k">
                            <td>{{k}}</td>
                            <td>{{printArray(v)}}</td>
                        </tr>
                    </table>
                </div>
                <div class="p-col-6">
                    <h3>User actions</h3>
                </div>
            </div>
        </template>
    </DataTable>
</template>

<script>
import Chart from "primevue/chart";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import MetricService from "@/services/metrics";
import TriStateCheckbox from "primevue/tristatecheckbox"
import Sidebar from 'primevue/sidebar';
import ProgressSpinner from 'primevue/progressspinner';
// import 'chartjs-plugin-colorschemes';

export default {
    name: "UsersData",
    components:{
        Chart,
        TriStateCheckbox,
        DataTable,
        Column,
        ProgressSpinner,
        Sidebar,
    },

    data(){
        return{
            ShowCharts:false,
            account:null,
            delta:0,
            Loading: true,
            expandedRows:[],
            TotalRecords: null,
            Charts:{
                Roles:{Name:""},
                Staff:{Name:""},
                Active:{Name:""},
                LoginLast7:{Name:""},
                LoginLast30:{Name:""},
                AddedLast7:{Name:""},
                AddedLast30:{Name:""},
            },
            chartOptions: {
                animation: {duration: 0},
                legend: {display: false},
                plugins: {
                    // colorschemes: {
                        // scheme: 'brewer.Paired12'
                    // }
                }
            },
            LazyFilters:{
                fields: ["UserID", "Email", "Username", "Job", "Role", "Head", "Department", "WorkPhone", "Phone", "Language", "Timezone", "ContentLanguage", "IsActive", "IsDeleted", "IsGuest", "IsStaff", "IsSuperuser", "Added", "LastLogin"],
                Offset: 0,
                page: 1,
                Limit: 20,
                SortField: null,
                SortOrder: null,
                filters: {},
                delta: 0,
            },
            Account: null,
            data:null,
            roles:[],
            isStaff:null,
            isActive:true,
        }
    },
    service: null,
    created() {
        this.service = new MetricService()
    },
    mounted() {
        this.account = this.$router.currentRoute.value.params.account;
        this.loadUsers()
    },
    methods: {
        onPage(event) {
            Object.assign(this.LazyFilters, {
                Offset: event.first,
                page: event.page,
                Limit: event.rows,
                pageCount: event.pageCount,
                SortField: event.sortField,
                SortOrder: event.sortOrder
            })
            this.loadUsers()
        },
        _prepareFilters(){
            let filters = {"AccountShortname": this.account}

            for (const [key, value] of Object.entries(this.LazyFilters.filters)) {
                if (key !== "IsActive" && key !== "IsStaff") {filters[key] = value}
            }

            if (this.isActive !== null) {
                filters["IsActive"] = this.isActive === true
            }

            if (this.isStaff !== null) {
                filters['IsStaff'] = this.isStaff === true
            }

            this.LazyFilters.filters = filters
        },
        exportCSV(){
            this._prepareFilters()
            this.service.exportUsers(this.LazyFilters)
        },
        showCharts() {
            for (let [key, ] of Object.entries(this.Charts)) {
                this.service.getUserCharts(this.account, key).then(data => {
                    let l = data.XAxis
                    let d = data.YAxis

                    this.Charts[key] ={
                        Name:data.Name,
                        labels: l,
                        datasets: [{borderWidth: 0, data: d}]}
                })

            }

            this.ShowCharts = true
        },
        loadUsers(){

            this.Loading = true;
            this._prepareFilters()

            this.service.getUsers(this.LazyFilters).then(data => {
                this.roles = [];

                if (data.total === 0) {
                    this.Loading = false
                    return
                }

                this.data = data.users
                this.TotalRecords = data.total
                this.roles = data.roles

                this.Loading = false;

            })
        }
    }
}
</script>
<style scoped>
.charts div h3 {
    text-align:center;
    font-weight:normal;
}
</style>
